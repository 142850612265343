/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

// GENERAL
// ---------------

$color1:              #005058; // blue
$color2:              #f47e63; // orange
$color3:              #f7a491; // orange hover
$color4:              #f2f6f6; // light blue
$color5:              #4c848a; // blue hover

// TYPOGRAPHY
// ---------------

$textColor:           #000;

$baseFontSize:        16px;
$em-base:             $baseFontSize;
$baseLineHeight:      1.612;
$baseTypeSpacing:     round($baseFontSize * $baseLineHeight);
$baseFontFamily:      fieldwork, Helvetica, Arial, sans-serif;
$secondaryFontFamily: $baseFontFamily;

$headingsFontFamily:  $secondaryFontFamily;
$headingsColor:       $color1;

$hrColor:             #DDD;
